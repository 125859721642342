<script lang="ts" setup>
import Avatar from '@/components/Avatar.vue'
import SkrHeading from '@/components/SkrHeading.vue'
import OverlayBase from '@/components/overlays/OverlayBase.vue'
import SkrButtonGroup from '@/components/button/SkrButtonGroup.vue'
import SkrButton from '@/components/button/SkrButton.vue'

const props = withDefaults(
  defineProps<{
    processedRequests?: string[]
    lockData?: SignLockData
  }>(),
  {
    processedRequests: (): string[] => [],
  }
)

const emit = defineEmits<{
  (e: 'action', type: OverlayAction, data?: unknown): void
  (e: 'close'): void
}>()

const { t } = useI18n()

const { isDirectSign } = storeToRefs(useDirectSignStore())

const isSingleDocument = computed(() => props.processedRequests.length < 2)

const title = computed(() => {
  if (isSingleDocument.value) {
    return isLocked.value ? t('sign.locked.one.title_locked') : t('sign.locked.one.title_unlocked')
  } else {
    return isLocked.value ? t('sign.locked.many.title_locked') : t('sign.locked.many.title_unlocked')
  }
})

const subtitle = computed(() => {
  return isSingleDocument.value ? t('sign.locked.one.subtitle') : t('sign.locked.many.subtitle')
})

const lockingUserName = computed(() => {
  if (props.lockData?.firstName && props.lockData?.lastName) {
    return `${props.lockData.firstName} ${props.lockData.lastName}`
  } else {
    return t('global.someone_else')
  }
})

const isLocked = ref(Boolean(props.lockData))

const lockInterval = ref(0)

const { srRepository, directSrRepository } = useApi()

const checkLockStatus = async () => {
  const { locked } = isDirectSign.value
    ? await directSrRepository.checkLock(props.lockData!.signatureRequestId)
    : await srRepository.checkLock(props.lockData!.signatureRequestId)

  isLocked.value = locked

  if (!isLocked.value && lockInterval.value) {
    clearInterval(lockInterval.value)
    lockInterval.value = 0
  }
}

const sign = () => {
  emit('action', 'retry', { id: props.lockData?.signatureRequestId })
}

onMounted(() => {
  checkLockStatus().then(() => {
    if (isLocked.value) {
      lockInterval.value = window.setInterval(checkLockStatus, 3000)
    }
  })
})

onBeforeUnmount(() => {
  if (lockInterval.value) {
    clearInterval(lockInterval.value)
    lockInterval.value = 0
  }
})
</script>

<template>
  <overlay-base :transition="false">
    <v-container :class="{ 'fill-height': $vuetify.display.mdAndUp }">
      <v-row justify="center">
        <v-col cols="12" md="6">
          <div class="d-flex flex-column align-center">
            <div class="mb-12 text-center">
              <skr-heading level="1" data-cy="title" semantic>{{ title }}</skr-heading>
              <skr-heading v-if="isLocked" level="2">{{ subtitle }}</skr-heading>
            </div>
            <avatar :first-name="lockData?.firstName" :last-name="lockData?.lastName" />
            <div>
              <span>{{ lockingUserName }}</span>
              {{ ' ' }}
              <span>{{ isLocked ? t('sign.locked.signer_locked') : t('sign.locked.signer_unlocked') }}</span>
            </div>
            <skr-button-group size="lg">
              <skr-button type="secondary" @click="emit('close')">
                {{ isSingleDocument ? t('sign.error.one.back') : t('sign.error.many.back') }}
              </skr-button>
              <skr-button :disabled="isLocked" :loading="isLocked" @click="sign">
                {{ t('global.sign_now') }}
              </skr-button>
            </skr-button-group>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </overlay-base>
</template>
